import * as React from 'react';
import './ColourAccessibilityControl.scss';

export default function ColourAccessibilityControl (props) {
  return (
    <div className="ColourAccessibilityControl">
      <div style={{ backgroundColor: props.storyColour }} onClick={() => props.clickHandler(props.storyColour)}></div>
      <div style={{ backgroundColor: "#fff" }} onClick={() => props.clickHandler("#fff")}></div>
      <div style={{ backgroundColor: "#000" }} onClick={() => props.clickHandler("#000")}></div>
    </div>
  )
}