import * as React from 'react'
import "./DefDrawer.scss"

export default function DefDrawer (props) {
  if (props.isOpen) {
    return (
      <div className="def-drawer">
        <div className="def-drawer-close" onClick={props.closeDrawer}>
          <svg xmlns="http://www.w3.org/2000/svg" width="19.139" height="19.36" viewBox="0 0 19.139 19.36">
            <g id="Group_410" data-name="Group 410" transform="translate(-329.897 -27.71)">
              <line id="Line_59" data-name="Line 59" x1="26.066" transform="translate(330.251 28.284) rotate(45)" fill="none" stroke="#000" strokeWidth="1"/>
              <path id="Path_286" data-name="Path 286" d="M26.066,0H0" transform="translate(330.251 46.495) rotate(-45)" fill="none" stroke="#000" strokeWidth="1"/>
            </g>
          </svg>
        </div>
        <div className="def-drawer-content">
          <p className="def-drawer-label">{props.label}:</p>
          <p dangerouslySetInnerHTML={{__html: props.definition}}></p>
        </div>
      </div>
    )
  } else {
    return (
      <></>
    );
  }
}