import * as React from 'react'
import './StorySlide.scss'
import StorySlideButton from "../StorySlideButton/StorySlideButton";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import ColourAccessibilityControl from "../ColourAccessibilityControl/ColourAccessibilityControl"
import TextAccessibilityControl from "../TextAccessibilityControl/TextAccessibilityControl";

class StorySlide extends React.Component {
  
  constructor (props) {
    super(props);
    this.state = {
      storyBackgroundColour: this.props.storyData.colour,
      storyTextColour: this.props.storyData.textColour ? "#000" : "#fff",
      storyFontSize: 14,
      respondentName: "",
      respondentBackground: "",
      storyTranscript: "",
      storyAudioSrc: "",
      mobileTranscriptOpen: false,
    }
  }

  handleColourChange = (colour) => {
    if (colour == "#000") {
      this.props.updateNavColour("#fff")
      this.setState({storyBackgroundColour: colour, storyTextColour: "#fff"})
    } 
    else if (colour == "#fff") {
      this.props.updateNavColour("#000")
      this.setState({storyBackgroundColour: colour, storyTextColour: "#000"})
    }
    else {
      this.props.updateNavColour(this.props.storyData.textColour ? "#000" : "#fff")
      this.setState({
                     storyBackgroundColour: this.props.storyData.colour, 
                     storyTextColour: this.props.storyData.textColour ? "#000" : "#fff"
                    })
    }
  }

  getStorySlideClass = () => {
    return "story-slide";
  }

  incFontSize = () => {
    if (this.state.storyFontSize < 24) {
      this.setState({
        storyFontSize: this.state.storyFontSize + 2
      })
    }
  }

  decFontSize = () => {
    if (this.state.storyFontSize > 10) {
      this.setState({
        storyFontSize: this.state.storyFontSize - 2
      })
    }
  }

 render () {
  if (this.props.isDesktop) {

    return (
      <div className="story-slide-container" style={{backgroundColor: this.state.storyBackgroundColour, color: this.state.storyTextColour, borderColor: this.state.storyTextColour }}>
        <StorySlideButton storyIndex={this.props.storyIndex} storyBackgroundColour={this.props.storyData.colour} storyTextColour={this.props.storyData.textColour ? "#000" : "#fff"} storyActiveBackgroundColour={this.state.storyBackgroundColour} storyActiveTextColour={this.state.storyTextColour} currentStoryIndex={this.props.currentStoryIndex} storySlideButtonHandler={this.props.storySlideButtonHandler} />
        <div className="story-slide">

        <div className="leftPanel">
          <p className="speakerID">{this.props.storyData.name}, {this.props.storyData.culturalBackground}, {this.props.storyData.age}, {this.props.storyData.location}</p>
          <h1 className="question">{this.props.storyData.prompt.internal.content}</h1>
          <AudioPlayer audio={this.props.storyData.interview.file.url} strokeColour={this.state.storyTextColour} isCurrentSlide={this.props.storyIndex === this.props.currentStoryIndex} />
        </div>
      
        <div className="rightPanel" id="rightPanel1">

        <div className="accesibility-controls">

          <TextAccessibilityControl incFontSize={this.incFontSize} decFontSize={this.decFontSize} />
          <ColourAccessibilityControl storyColour={this.props.storyData.colour} borderColour={this.state.storyTextColour} clickHandler={this.handleColourChange} />
        </div>
      
          <div className="transcript">
          {
            JSON.parse(this.props.storyData.transcript.raw).content.map((el, i) => {
              return (<p key={i} style={{ fontSize: `${this.state.storyFontSize}px`}}>{el.content[0].value}</p>);
            })
          }
          </div>
        </div>
        
      </div>
      <StorySlideButton direction="prev" storyBackgroundColour={this.props.storyData.colour} storyTextColour={this.props.storyData.textColour ? "#000" : "#fff"} storyActiveBackgroundColour={this.state.storyBackgroundColour} storyActiveTextColour={this.state.storyTextColour} storyIndex={this.props.storyIndex} currentStoryIndex={this.props.currentStoryIndex} storySlideButtonHandler={this.props.storySlideButtonHandler} />
    </div>
   )
  } else {
    if (this.props.currentStoryIndex === this.props.storyIndex) {
      return (
        <>
        <div className="story-slide-container" style={{backgroundColor: this.props.storyData.colour, color: this.props.storyData.textColour ? "#000" : "#fff", borderColor: this.props.storyData.textColour ? "#000" : "#fff" }}>
          <div className="story-slide-mobile">
            <div className="story-slide-mobile-header">
              <h1>Story {this.props.storyIndex}</h1>
              <div className="transcript-toggle" onClick={() => this.setState({mobileTranscriptOpen: true})} style={{borderColor: this.props.storyData.textColour ? "#000" : "#fff" }}>
                <p>TRANSCRIPT</p>  
              </div>
            </div>
            <p className="speakerID">{this.props.storyData.name}, {this.props.storyData.culturalBackground}, {this.props.storyData.age}, {this.props.storyData.location}</p>
            <h2 className="question">{this.props.storyData.prompt.internal.content}</h2>
            <AudioPlayer audio={this.props.storyData.interview.file.url} strokeColour={this.props.storyData.textColour ? "#000" : "#fff"} isCurrentSlide={this.props.storyIndex === this.props.currentStoryIndex} />
          </div>
        </div>
        <div className={this.state.mobileTranscriptOpen ? "mobile-transcript-container" : "mobile-transcript-container hidden"}>
          <div className="mobile-transcript-close" onClick={() => this.setState({mobileTranscriptOpen: false})}>
            <div></div>
            <div></div>
          </div>
          <div className="transcript">
            {
              JSON.parse(this.props.storyData.transcript.raw).content.map(el => {
                return (<p style={{ fontSize: `${this.state.storyFontSize}px`}}>{el.content[0].value}</p>);
              })
            }
          </div>
        </div>
        </>
      )
    } else if (this.props.currentStoryIndex === this.props.storyIndex - 1 || this.props.currentStoryIndex === this.props.storyIndex + 1) {
      return (
        <div className="story-slide-container inactive" style={{backgroundColor: this.props.storyData.colour, color: this.props.storyData.textColour ? "#000" : "#fff", borderColor: this.props.storyData.textColour ? "#000" : "#fff" }}>
        <div className="story-slide-mobile">
          
          <div className="story-slide-mobile-header" onClick={() => this.props.storySlideButtonHandler(this.props.storyIndex)}>
            <h1>Story {this.props.storyIndex}</h1>
          </div>
          </div>
    </div>
      )
    } else {
      return <></>
    }
  }
 } 

}

export default StorySlide;