import * as React from 'react'
import "./AudioPlayer.scss"

const str_pad_left = (string,pad,length) => {
  return (new Array(length+1).join(pad)+string).slice(-length);
}

const isBrowser = typeof window !== "undefined";

class AudioPlayer extends React.Component {

  constructor(props) {
    super();
    this.state = {
      active: false,
      contentLoaded: false,
      currentTimePlayed: null,
      progress: null
    };
    this.audioRef = React.createRef();
  }

  onLoadedMetadata = () => {
    if (this.audioRef.current) {
      this.setState({
        contentLoaded: true
      })
    }
  };

  componentDidUpdate = () => {
    if (!this.props.isCurrentSlide && this.state.active) {
      this.setState({
        active: false
      }, () => {
        this.audioRef.current.pause();
      })
    }
  }

  calcTimeInMinutes = (timeInSeconds) => {
    if (this.state.contentLoaded) {
      let minutes = Math.floor(timeInSeconds / 60),
          seconds = Math.floor(timeInSeconds - minutes * 60);
      return minutes+':'+str_pad_left(seconds,'0',2);
    } else {
      return "--:--"
    }
  }

  setCurrentTimePlayed = () => {
    this.setState({
      currentTimePlayed: this.calcTimeInMinutes(this.audioRef.current.currentTime)
    }, this.setProgress)
  }

  setProgress() {
    let progress = (this.audioRef.current.currentTime / this.audioRef.current.duration);
    if (progress >= 1) {
      this.setState({
        progress: null,
        currentTimePlayed: null,
        active: false
      })
    } else {
      this.setState({
        progress: progress
      })
    }
  }

  handleClick() {
    this.setState({
      active: !this.state.active
    }, () => {
      if (this.state.active) {
        this.audioRef.current.play();
      } else {
        this.audioRef.current.pause();
      }
    })
  }

  isDesktop = () => {
    if (isBrowser) {
      return window.innerWidth > 1200;
    } else {
      return true;
    }
  }

  render() {
    if (this.isDesktop()) {
      const circumference = window.innerWidth /4 * 3.14;
      return (
        <div className='audio-player'>
          <audio 
            ref={this.audioRef}
            onLoadedMetadata={this.onLoadedMetadata}
            onTimeUpdate={this.setCurrentTimePlayed}
            src={this.props.audio}
          />
          <div className="audio-player-text">
            <p className="audio-player-button" onClick={this.handleClick.bind(this)}>
              {!this.state.active &&
                `Play`
              }
              {this.state.active && 
                `Pause`
              }
            </p>
            <p>{this.state.currentTimePlayed ? this.state.currentTimePlayed + "/" : ""}{this.calcTimeInMinutes(this.state.contentLoaded ? this.audioRef.current.duration : "--:--")}</p>
          </div>
          <svg
            className="progress-ring"
            height="100%"
            width="100%"
          >
            <circle
              className="player-circle-base"
              strokeWidth="1"
              stroke={this.props.strokeColour}
              fill="transparent"
              r="calc(50% - 6px)"
              cx="50%"
              cy="50%"
            />
            <circle
              className="progress-ring-circle"
              strokeWidth="3"
              stroke={this.props.strokeColour}
              fill="transparent"
              r="calc(50% - 6px)"
              cx="50%"
              cy="50%"
              style={{strokeDasharray: `${circumference * this.state.progress} ${circumference - circumference * this.state.progress}` }}
            />
          </svg>
        </div>
      );
    } else {
      return (
        <div className='audio-player--mobile'>
          <div className="audio-player-text">
            <h2 className="audio-player-button" onClick={this.handleClick.bind(this)}>
              {!this.state.active &&
                "Play"
              }
              {this.state.active && 
                "Pause"
              }
            </h2>
            <h2>{this.state.currentTimePlayed ? this.state.currentTimePlayed + "/" : ""}{this.calcTimeInMinutes(this.state.contentLoaded ? this.audioRef.current.duration : "--:--")}</h2>
          </div>
          <audio 
            ref={this.audioRef}
            onLoadedMetadata={this.onLoadedMetadata}
            onTimeUpdate={this.setCurrentTimePlayed}
            src={this.props.audio}
          />
          <svg
            className="progress-line"
            height="3px"
            width="100%"
          >
            <line x1="0" y1="1.25" x2="100%" y2="1.25" stroke-width="1px" stroke={this.props.strokeColour}></line>
            <line x1="0" y1="1" x2={`${this.state.progress * 100}%`} y2="1" stroke-width="3px" stroke={this.props.strokeColour}></line>
          </svg>
        </div>
      );
    }
  }
}

export default AudioPlayer;