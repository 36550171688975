import * as React from "react";
import { Link } from "gatsby";
import "../styles/index.scss"
import Header from "../components/Header/Header";
import Nav from "../components/Nav/Nav";
import DefLink from "../components/DefLink/DefLink";
import DefDrawer from "../components/DefDrawer/DefDrawer";
import StorySlideContainer from "../components/StorySlideContainer/StorySlideContainer";
import ccaLockup from "../images/cca-lockup.png";
import JA1 from "../images/JA1.png";
import JA2 from "../images/JA2.png";
import JK1 from "../images/JK1.png";
import JK2 from "../images/JK2.png";
import KY1 from "../images/KY1.png";
import KY2 from "../images/KY2.png";

const isBrowser = typeof window !== "undefined";

const Purple = "#B99EFF",
      Orange = "#FF9545",
      Pink = "#FEA1D7";

const colours = [Purple, Orange, Pink]

const supportImages = [
  {
    path: KY1,
    top: 0,
    left: "30%",
    right: "unset",
    bottom: "unset"

  },
  {
    path: KY2,
    top: "unset",
    left: "50%",
    right: "unset",
    bottom: 0
  },
  {
    path: JA2,
    top: "88px",
    left: 0,
    right: "unset",
    bottom: "unset"
  },
  {
    path: JA1,
    top: "unset",
    left: "50%",
    right: "unset",
    bottom: "105px"
  },
  {
    path: JK1,
    top: "261px",
    left: "unset",
    right: "50%",
    bottom: "unset"
  },
  {
    path: JK2,
    top: 0,
    left: "unset",
    right: "60px",
    bottom: "unset"
  }
]

class IndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isDesktop: true,
      defDrawerOpen: false,
      defDrawerLabel: "",
      defDrawerDefinition: "",
      currentStoryIndex: 0,
      navColour: "#000",
      storySlideHoverActive: false,
      supportButtonBackgroundColour: "#fff",
      supportButtonHover: false,
      supportImages: []
    }
  }

  updateNavColour = (colour) => {
    this.setState({
      navColour: colour
    })
  }

  setDisplaySettings = () => {
    if (isBrowser) {
      this.setState({
        isDesktop: window.innerWidth > 1200
      })
    }
  }

  componentWillMount() {
    this.setButtonColour();
  }

  componentDidMount() {
    this.setSupportImages();
    this.setDisplaySettings();
    window.addEventListener('resize', this.setDisplaySettings);
    this.setState({loading: false});
    if (this.props.location.state && this.props.location.state.storyIndexFromLibrary !== null) {
      this.setState({
        currentStoryIndex: this.props.location.state.storyIndexFromLibrary,
      }, this.updateNavColour(this.props.location.state.navColour))
    } else {
      this.setState({
        currentStoryIndex: 0
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setDisplaySettings);
  }

  resetCurrentStoryIndex = () => {
    this.setState({
      currentStoryIndex: 0,
      navColour: "#000"
    })
  }

  handleDefLinkClick = (label="", definition="") => {
    this.setState({
      defDrawerOpen: true,
      defDrawerLabel: label,
      defDrawerDefinition: definition
    })
  }

  closeDefDrawer = () => {
    this.setState({
      defDrawerOpen: false,
      defDrawerLabel: "",
      defDrawerDefinition: ""
    })
  }

  handleStorySlideButton = (storyIndex, storyActiveBackgroundColour, storyActiveTextColour = "#000") => {
      if (storyActiveBackgroundColour === "#000") {
        this.setState({
          currentStoryIndex: storyIndex,
          navColour: "#fff"
        })
      } 
      else if (storyActiveTextColour === "#fff") {
        this.setState({
          currentStoryIndex: storyIndex,
          navColour: "#fff"
        })
      } else {
        this.setState({
          currentStoryIndex: storyIndex,
          navColour: "#000"
        })
      }
  }

  isDesktop = () => {
    return window.innerWidth > 1200;
  }

  calcStorySlideWrapperPosDesktop = () => {
    if (this.state.isDesktop) {
      if (this.state.currentStoryIndex === 0) {
        return "calc(100vw - 60px)"
      } else if (this.state.currentStoryIndex === 1) {
        return "0";
      } else {
        return `calc(${(this.state.currentStoryIndex - 1) * 120}px - ${(this.state.currentStoryIndex - 1) * 100}vw)`
      }
    } else {
      return 0;
    }
  }

  calcStorySlideWrapperPosMobile = () => {
    if (this.state.isDesktop) {
      return 0;
    } else {
      if (this.state.currentStoryIndex === 0) {
          return "calc(100% - 168px)";
      } else if (this.state.currentStoryIndex === 1) {
        return "0";
      } else {
        return "-52px"
      }
    }
  }

  toggleStorySlideHover = () => {
    this.setState({
      storySlideHoverActive: !this.state.storySlideHoverActive
    })
  }

  setButtonColour = () => {
    this.setState({
      supportButtonBackgroundColour: colours[Math.round(Math.random() * (colours.length - 1))],
      supportButtonColour: "#000"
    })
  }

  setSupportImages = () => {
    switch(this.state.supportButtonBackgroundColour) {
      case Purple:
        this.setState({
          supportImages: [supportImages[0], supportImages[1]]
        })
        break;
      case Orange:
        this.setState({
          supportImages: [supportImages[2], supportImages[3]]
        })
        break;
      case Pink:
        this.setState({
          supportImages: [supportImages[4], supportImages[5]]
        })
        break;
      default:
        this.setState({
          supportImages: [supportImages[4], supportImages[1]]
        })
    }
  }

  toggleSupportButtonHoverStyles = e => {
    console.log("hover!");
    e.preventDefault();
    this.setState({
      supportButtonHover: !this.state.supportButtonHover,
      showSupportImages: !this.state.showSupportImages
    })
  }

  render () {
    if (this.state.loading) {
      return (
        <>
          <Header/>
          <div className="wrapper">
            <Nav />
          </div>
        </>
      )
    } else {
      return (
        <>
        <Header/>
        <style>
          {`
            html, 
            body {
              position: relative;
              overflow: hidden;
            }
          `}
        </style>
        <div className="wrapper" onClick={this.state.defDrawerOpen ? this.closeDefDrawer : undefined}>
          {this.state.isDesktop && <img className={this.state.showSupportImages ? "support-image" : "support-image hidden"} src={this.state.supportImages[0].path} style={{top: this.state.supportImages[0].top, right: this.state.supportImages[0].right, bottom: this.state.supportImages[0].bottom, left: this.state.supportImages[0].left}} />}
          {this.state.isDesktop && <img className={this.state.showSupportImages ? "support-image" : "support-image hidden"} src={this.state.supportImages[1].path} style={{top: this.state.supportImages[1].top, right: this.state.supportImages[1].right, bottom: this.state.supportImages[1].bottom, left: this.state.supportImages[1].left}} />}
          <Nav homeLinkListener={this.resetCurrentStoryIndex} navColour={this.state.isDesktop ? this.state.navColour : "#000"} />
          <div className="intro">
            <h1><i>here-there</i> is a library of stories shared by members of the <DefLink defLinkText="Asian Canadian diaspora" clickHandler={() => this.handleDefLinkClick("Asian Canadian diaspora", "Those within a diaspora share an “identification with a place, a history, some elements of culture, a few motivating and guiding principles, or simply an awareness of whence they came” (Djao, 2003) — regardless of migration or current residence.<br><br>The term “Asian Canadian diaspora” applies to anyone who self-identifies as both Asian (East, South, or Southeast) and Canadian. We encourage the participation of anyone who finds connection in living with both of these titles.<br><br>Source:<br>Djao, W. (2003). <i>Being Chinese: Voices from the Diaspora</i>. University of Arizona Press.")} isDesktop={this.state.isDesktop} />. It is a place to explore what holds us together, as individuals and as a community. Each unguided story follows a <DefLink defLinkText="“pass-it-on”" clickHandler={() => this.handleDefLinkClick("Pass-it-on", "The pass-it-on structure means each contributor begins by answering a question asked by the previous person and ends their recording by leaving a question for the following person — creating a linear chain that connects all the stories.")} isDesktop={this.state.isDesktop} /> structure that weaves an anthology of lived histories and experiences. Collectively, it is a space to contemplate the nuanced journeys of those who live both here and there.</h1>
            <div className="index-buttons-container">
              <button className="start-button" onMouseEnter={this.toggleStorySlideHover} onMouseLeave={this.toggleStorySlideHover} onClick={() => this.setState({currentStoryIndex: 1})}>Start the Journey {this.state.isDesktop ? "→" : "↓"}</button>
              <a href="https://herethereshop.square.site/" target="_blank" rel="noreferrer noopener" className="support-button"  onMouseOver={this.toggleSupportButtonHoverStyles} onMouseLeave={this.toggleSupportButtonHoverStyles} style={{backgroundColor: this.state.supportButtonBackgroundColour, color: this.state.supportButtonColour, textFillColor: this.state.supportButtonColour, WebkitTextFillColor: this.state.supportButtonColour}} >Donate & Support ◊</a>
            </div>
            <a className="cca-link" href="https://canadacouncil.ca/" target="_blank" rel="noreferrer noopener">
              <img className="cca-lockup" src={ccaLockup} />
            </a>
          </div>
        </div>
        <DefDrawer isOpen={this.state.defDrawerOpen} label={this.state.defDrawerLabel} definition={this.state.defDrawerDefinition} closeDrawer={this.closeDefDrawer} />
        <StorySlideContainer 
          storySlideHoverClass={this.state.storySlideHoverActive ? "hovered" : ""}
          handleHover={this.toggleStorySlideHover}
          isDesktop={this.state.isDesktop} 
          left={this.calcStorySlideWrapperPosDesktop()} 
          top={this.calcStorySlideWrapperPosMobile()} 
          currentStoryIndex={this.state.currentStoryIndex} 
          handleStorySlideButton={this.handleStorySlideButton}
          updateNavColour={this.updateNavColour} />
        </>
      )
    }
  }

}

export default IndexPage;