import * as React from "react";
import './StorySlideButton.scss';

export default function StorySlideButton (props) {

  const storyNextOrPrev = () => {
    return props.storyIndex - props.currentStoryIndex === 1 ||props.storyIndex - props.currentStoryIndex === -1;
  }

  const getStorySlideButtonClass = () => {
    if (storyNextOrPrev()) {
      return "slide-button"
    } else {
     return "slide-button hidden"
    }
  }
  
  const getStorySlideBackgroundColour = () => {
    if (props.storyIndex - props.currentStoryIndex === 1 || props.storyIndex - props.currentStoryIndex === -1) {
      return props.storyBackgroundColour;
    } else {
      return props.storyActiveBackgroundColour;
    }
  }

  return (
    <div className={getStorySlideButtonClass()} style={{backgroundColor: storyNextOrPrev() ? getStorySlideBackgroundColour() : "transparent", color: props.storyTextColour}} onClick={() => props.storySlideButtonHandler(props.storyIndex, props.storyActiveBackgroundColour, props.storyActiveTextColour)}>
      <h1>Story {props.storyIndex}</h1>
    </div>
  )
}