import * as React from 'react';
import "./TextAccessibilityControl.scss";

export default function TextAccessibilityControl (props) {
  return (
    <div className="TextAccessibilityControl">
      <h2>AA</h2>
      <div className="plus" onClick={props.incFontSize}>
        <div></div>
        <div></div>
      </div>
      <div className="minus" onClick={props.decFontSize}>
        <div></div>
      </div>
    </div>
  )
}