import * as React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";
import StorySlide from "../StorySlide/StorySlide";

const StorySlideContainer = (props) => {

  const classList = () => {
    return props.currentStoryIndex === 0 ? `storySlideWrapper ${props.storySlideHoverClass}` : `storySlideWrapper`;
  }

  const data = useStaticQuery(graphql`
  query storyQuery {
    allContentfulStory (sort: {fields: [createdAt], order: ASC}) {
      edges {
        node {
            id
            prompt {
              internal {
                content
              }
            }
            name
            culturalBackground
            age
            location
            transcript {
              raw
            }
            interview {
              file {
                url
              }
            }
            colour
            textColour
          }
        }
      }
    }
  `);

  return (
  <div className={classList()} 
       style={{left: props.left, top: props.top}}>
    {    
      data.allContentfulStory.edges.map( (element,i) => {
      return (<StorySlide 
                  storyIndex={i+1}
                  key={i.toString()} 
                  storyData={element.node} 
                  currentStoryIndex={props.currentStoryIndex} 
                  storySlideButtonHandler={props.handleStorySlideButton}
                  isDesktop={props.isDesktop}
                  updateNavColour={props.updateNavColour} 
                  />);
      })
    }
    <Link to="/participate" style={{textDecoration: "none"}}>
      <div className="slide-button" style={{backgroundColor: "DarkKhaki", color: "black"}}>
        <h1>Share Your Story</h1>
      </div>
    </Link>
  </div>
  )
}

export default StorySlideContainer;